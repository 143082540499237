import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
// import Header from "../components/header"

const Terms = () => (
  <div className="abtus">
  <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Terms and Conditions | Coinsclone</title>
        <meta name="description" content="Welcome to coinsclone.com., By accessing this website we assume you accept these terms and conditions." />
        <link rel="canonical" href="https://www.coinsclone.com/terms-and-conditions/" />
      </Helmet>
      {/* <Header /> */}
    <section className="banner terms">
      <div className="container">
        <div className="row table-content">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h1 className="main-heading">Terms and Conditions</h1>
                <p className="pharagraph">Welcome to coinsclone.com., By accessing this website we assume you accept these terms and conditions. Do not continue to use our  website if you do not agree to take all of the terms and conditions stated on this page. </p>
                <p className="pharagraph">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                <h2 className="sub-heading">License:</h2>
                <p className="pharagraph">Unless otherwise stated,  coinsclone and/or its licensors own the intellectual property rights for all material on Website  All intellectual property rights are reserved. You may access this from coinsclone.com for your own personal use subjected to restrictions set in these terms and conditions.  You must not: Republish material, Sell, rent or sub-license material from coinsclone.com. </p>
                <h2 className="sub-heading">Cookies:</h2>
                <p className="pharagraph">We employ the use of cookies. By accessing our  website, you agreed to use cookies in agreement with the Coinsclone. Some of our affiliate/advertising partners may also use cookies. </p>
                <h2 className="sub-heading">Links/Backlinks:  </h2>
                <p className="pharagraph">The following organizations may link to our Website without prior written approval</p>
                <p className="pharagraph">Government agencies; Search engines; News organizations; Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</p>
                <p className="pharagraph">These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site. We may consider and approve other link requests from the following types of organizations.</p>
                <h3 className="sub-heading">IFrames Loading/Linking:  </h3>
                <p className="pharagraph">Without prior approval and written permission, you may not create frames around our WebPages that alter in any way the visual presentation or appearance of our Website.</p>
                <h3 className="sub-heading">Content Liability:   </h3>
                <p className="pharagraph">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                <h3 className="sub-heading">Removal of links: </h3>
                <p className="pharagraph">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly. We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
                <h3 className="sub-heading">Disclaimer:</h3>
                <p className="pharagraph">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will</p>
                <p className="pharagraph">Limit or exclude our or your liability for death or personal injury; Limit or exclude our or your liability for fraud or fraudulent misrepresentation; Limit any of our or your liabilities in any way that is not permitted under applicable law; or Exclude any of our or your liabilities that may not be excluded under applicable law.</p>
                <p className="pharagraph">The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
  </div>
)

export default Terms
